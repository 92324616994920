import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getCountries() {
  return api
    .getAuth(ENDPOINTS.COUNTRIES)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {});
}
